export default {
  basicViewItem: [{
    prop: 'online',
    label: '',
    Object: 'value',
    span: 12,
    back: true
  }, {
    prop: 'discount_type',
    label: '',
    Object: 'value',
    span: 12
  }, {
    prop: 'start_date',
    label: '',
    Object: 'value',
    span: 12
  }, {
    prop: 'end_date',
    label: '',
    Object: 'value',
    span: 12
  }, {
    prop: 'name',
    label: '',
    Object: 'value',
    span: 12
  }, {
    prop: 'name_en',
    label: '',
    Object: 'value',
    span: 12
  }, {
    prop: 'created_at',
    label: '',
    Object: 'value',
    span: 12
  }, {
    prop: 'updated_at',
    label: '',
    Object: 'value',
    span: 12
  }, {
    prop: 'short_description',
    label: '',
    Object: 'value',
    span: 24
  }, {
    prop: 'short_description_en',
    label: '',
    Object: 'value',
    span: 24
  }, {
    prop: 'shop',
    label: '',
    Object: 'value',
    span: 24
  }, {
    prop: 'imageArry',
    image: true,
    label: '',
    Object: 'value',
    span: 24
  }],
  tableDataViewItem: [{
    prop: 'id',
    label: '',
    Object: 'value',
    width: '50'
  }, {
    prop: 'shop_item',
    label: '',
    Object: 'value'
    // width: '100'
  }, {
    prop: 'bundle_item_type',
    label: '',
    Object: 'value'
    // width: '100'
  }, {
    prop: 'shop_item_bundle',
    label: '',
    Object: 'value'
    // width: '100'
  }, {
    prop: 'discount_percent',
    label: '',
    Object: 'value'
  }]
}
