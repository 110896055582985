// 组件
import createTable from '@/components/createTable'
import viewFrame from '@/components/viewFrame'
import customeTable from '@/components/customeTable'
import dialogEffect from '@/components/dialogEffect'
// 接口
import * as bundleApi from '@/services/bundles.js'
import { getHistoryList } from '@/services/resource'
// 其他
import loading from '@/utils/loading.json'
import history from '@/common/data/jsonData'
import jsonData from './jsonData'
import { mixin } from '@/mixins/viewDiolog'

export default {
  mixins: [mixin],
  components: {
    createTable,
    viewFrame,
    customeTable,
    dialogEffect
  },
  data () {
    return {
      validateVisible: false,
      buttonObj: [],
      titleObject: {},
      custName: {},
      formData: {},
      modifyData: {},
      type: '',
      basicViewItem: jsonData.basicViewItem,
      historyTableItem: history.historyTableItem,
      tableDataViewItem: jsonData.tableDataViewItem,
      dialogData: [],
      batchData: [],
      dialogVisible: false,
      historyTableData: [],
      language: this.$cookies.get('language') || navigator.language.split('-')[0] || navigator.userLanguage.split('-')[0] || 'en'
    }
  },
  created () {
    const _this = this
    _this.urlParams = _this.$route.params
  },
  mounted () {
    const _this = this
    _this.init()
  },
  methods: {
    setGlobalLoading (show) {
      const _this = this
      show ? _this.$store.state.app.loading = _this.$loading(loading) : _this.$store.state.app.loading.close()
    },
    // 初始化
    async init () {
      const _this = this
      _this.setGlobalLoading(true)
	  const res = await bundleApi.getBundleDetail(_this.urlParams.id)
	  if (res.status === 200) {
		  _this.custName = res.data.shop_item_bundle
		  
		  const obj = { name: '', value: [] }
		  const { images } = res.data.shop_item_bundle
		  if (images && images.length > 0) {
		    images.forEach(i => {
		      obj.value.push({ value: i.image.value })
		    })
		    obj.name = images[0].image.name
		    _this.custName.basic_information.imageArry = obj
		  }
		  
		  const discount_type = res.data.shop_item_bundle.basic_information.discount_type.value
		  if ([this.$t('form.bundle.discountValue2'), '金额折扣', 'Money Value Off'].includes(discount_type)) {
		    this.tableDataViewItem[4].prop = 'discount_value_f'
		  } else {
		    this.tableDataViewItem[4].prop = 'discount_percent'
		  }
		  
		  Object.keys(this.custName.bundle_items[0]).forEach(item => {
		    const index = this.tableDataViewItem.findIndex(fditem => fditem.prop === item)
		    if (index !== -1) this.tableDataViewItem[index].label = this.custName.bundle_items[0][item].name
		  })
		  Object.assign(this.titleObject, { title: this.$t('form.bundle.detail') + ': ', data: this.language === 'en' ? _this.custName.basic_information.name_en.value : _this.custName.basic_information.name.value })
	  }
	   _this.setGlobalLoading(false)
      //  获取历史操作
      getHistoryList({ item_type: 'ShopItemBundle', item_id: _this.urlParams.id }).then(res => {
        this.historyTableData = res.data.versions
      })
    },
    forwardToPage (data, name) {
      if (name === 'shop') {
        this.$router.push({
          name: 'shopsManagerDetail',
          params: {
            id: data.id
          }
        })
      }
    },
    // 点击操作按钮
    async handleClick (type, data) {
      console.log('---handleClick--data--', data)
      if (type?.action === 'edit') {
        this.$router.push({
          name: 'bundleEdit',
          params: {
            id: this.$route.params.id
          }
        })
      }
      if (type?.action === 'destroy') {
        this.deleteClick(this.$route.params.id, bundleApi.deleteBundle, 'bundleManagerList')
      }
    },
    handleOrderItemsTableForwardToPage (data, name) {
      console.log('---data---', data)
      console.log('---name---', name)
      if (name === 'shop_item') {
        this.$router.push({
          name: 'goodsManagerProductDetail',
          params: {
            id: data.id
          }
        })
      } else if (name === 'shop_item_bundle') {
        this.$router.push({
          name: 'bundleDetail',
          params: {
            id: data.id
          }
        })
      }
    }
  }
}
